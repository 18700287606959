import * as React from "react";

const BlogCommonFooter = () => {
 
    return (
     <div>
   <h1> Hey! I am a footer</h1>
     </div>

    )



}

export default BlogCommonFooter